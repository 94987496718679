import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["enterEmail", "email"];

    enterEmail(event) { }

    submit(event) {
        const form = document.getElementById('id_verification_form'),
              email = document.getElementById('email').value,
              emailConfirmation = document.getElementById('email_confirmation').value,
              messages = document.getElementById('server_messages'),
              stripeData = document.getElementById('identity_verification').dataset,
              registeredAppData = document.getElementById('registered_app').dataset,
              registeredAppIdentifier = registeredAppData['identifier'],
              publishableKey = stripeData['publishable_key'],
              idVerificationSessionsURL = stripeData['id_verification_sessions_url'],
              stripeSessionOpenedText = stripeData['opened_text'],
              errorText = stripeData['error_text'],
              invalidSubmissionText = stripeData['invalid_submission_text'],
              stripeApp = Stripe(publishableKey),
              mainElmt = document.getElementsByTagName('main')[0],
              resultsMsgDefault = document.getElementById('results_message_default').innerHTML,
              resultsMsgSessionCancelled = document.getElementById('results_message_session_cancelled').innerHTML,
              resultsMsgUnknownError = document.getElementById('results_message_unknown_error').innerHTML,
              idVerificationSessionParams = {
                  method: 'POST',
                  headers: { "Content-Type": "application/json",
                             "Accept": "application/json",
                             "X-Referrer-App": registeredAppIdentifier },
                  body: JSON.stringify({
                      id_verification_session: { email: email, email_confirmation: emailConfirmation }
                  })
              },
              responseHandler = function(response) {
                  if (response.ok) {
                      response
                          .json()
                          .then(sessionHandler)
                          .then(completionHandler);
                  } else {
                      response
                          .json()
                          .then(errorHandler);
                  }
                  return;
              },
              sessionHandler = function(session) {
                  return stripeApp.verifyIdentity(session.client_secret);
              },
              completionHandler = function(result) {
                  var error = result.error;
                  if (error === null) {
                      mainElmt.innerHTML = resultsMsgDefault;
                  } else {
                      var code = error.code;
                      if (code == "session_cancelled") {
                          mainElmt.innerHTML = resultsMsgSessionCancelled;
                      } else {
                          mainElmt.innerHTML = resultsMsgUnknownError;
                      }
                  }
              },
              errorHandler = function(error) {
                  setMessage("<h1>Error</h1>" + error.message );
              },
              setMessage = function(html) {
                  messages.innerHTML = html;
                  return;
              },
              emailConfirmed = function() {
                  return (email == emailConfirmation);
              },
              validSubmission = function() {
                  return (form.checkValidity() && emailConfirmed());
              },
              denySession = function() {
                  setMessage(errorText);
                  event.preventDefault();
              },
              disableForm = function() {
                  form.parentNode.classList.toggle('disable-form');
              },
              allowSession = function() {
                  event.preventDefault();
                  setMessage(stripeSessionOpenedText);
                  fetch(idVerificationSessionsURL,
                        idVerificationSessionParams)
                      .then(responseHandler)
                      .catch(errorHandler);
              };

        if (validSubmission()) {
            allowSession();
        } else {
            denySession();
        }
    }
}
