import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["role", "state", "district", "school", "submit"]

  update(event) {
    const params = event.params,
        recordName = params.recordName,
        recordId = event.target.selectedOptions[0].value,
        targetToUpdateName = params.targetToUpdate,
        targetToUpdateProperty = `${targetToUpdateName}Target`,
        targetToUpdate = this[targetToUpdateProperty],
        targetToUpdateCssId = targetToUpdate.id,
        requestPath = `/registrations/${targetToUpdateName}_listing`,
        requestParams = `?${recordName}_id=${recordId}&target=${targetToUpdateCssId}`,
        targetListingUrl = requestPath + requestParams;
    get(targetListingUrl, { responseKind: 'turbo-stream'});
    targetToUpdate.dispatchEvent(new Event('change'));
  }
}
